
import {defineComponent, ref} from 'vue';
import Error from '@/components/common/Error.vue';
import Errors from '@/library/model-collection/Errors';
import FormGroup from '@/components/common/form/FormGroup.vue';
import PasswordField from '@/components/common/form/PasswordField.vue';
import auth from '@/store/auth';
import axios from 'axios';
import {useRoute} from 'vue-router';

export default defineComponent({
    components: {
        Error,
        FormGroup,
        PasswordField,
    },
    setup() {
        const route = useRoute();
        const {email} = route.query;

        return {
            disableEmail: ref<boolean>(!!email),
            email: ref(email || ''),
            token: ref(route.query.token),
            password: ref<string>(''),
            errors: ref(new Errors()),
            loading: ref<boolean>(false),
        };
    },
    methods: {
        async changePassword() {
            this.loading = true;
            this.errors = new Errors();

            try {
                const response = await axios.post(
                    '/auth/reset/password/change',
                    {
                        token: this.token,
                        email: this.email,
                        password: this.password,
                        passwordConfirmation: this.password,
                    },
                );

                auth.setApiToken(response.data.apiToken);
            } catch (e: any) {
                this.password = '';

                if (!e.response || !e.response.data.errors) throw e;

                this.errors.setErrors(e.response.data.errors);

                return;
            } finally {
                this.loading = false;
            }

            await this.$router.push({name: 'home'});
        },
    },
});
