import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mt-12 d-grid" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PasswordField = _resolveComponent("PasswordField")!
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)), ["prevent"]))
  }, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('auth.resetPassword.title')), 1),
    _createVNode(_component_FormGroup, {
      modelValue: _ctx.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
      class: "mt-12",
      disabled: _ctx.disableEmail,
      "error-key": "email",
      errors: _ctx.errors,
      label: _ctx.$t('auth.email'),
      name: "email",
      type: "email"
    }, null, 8, ["modelValue", "disabled", "errors", "label"]),
    _createVNode(_component_PasswordField, {
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
      class: "mt-12",
      errors: _ctx.errors,
      label: _ctx.$t('auth.password')
    }, null, 8, ["modelValue", "errors", "label"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        disabled: _ctx.loading
      }, _toDisplayString(_ctx.$t('auth.actions.resetPassword')), 9, _hoisted_3)
    ]),
    _createVNode(_component_Error, {
      class: "text-center mt-4 d-block",
      "error-key": ['token', 'reset'],
      errors: _ctx.errors
    }, null, 8, ["errors"])
  ], 32))
}